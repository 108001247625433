import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./js/Home";
import Portafolio from "./js/Portafolio";
import NavbarHeader from './js/navbar';
import FooterC from "./js/footer";

function App() {
  return (
    <div>
      <BrowserRouter>
        <header className="sticky-top">
          <NavbarHeader />
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/portafolio" element={<Portafolio />} />
          <Route path="/*" element={<Home />}/>
        </Routes>
      </BrowserRouter>
      <FooterC />
    </div>
  );
}

export default App;
