import '../css/layout.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { analytics, db, logEvent } from "../config/fbconfig";

export default function FooterC() {
    return (
        <div>
            <footer className="footer">
                <div className="section-seperator">
                    <div className="content-md container">
                        <div className="row">
                            <div className="col-sm-2 sm-margin-b-30">
                                <ul className="list-unstyled footer-list">
                                    <li className="footer-list-item"><a href="/#body" onClick={() => { logEvent(analytics, "principal_visita") }}>Inicio</a></li>
                                    <li className="footer-list-item"><a href="/#about" onClick={() => { logEvent(analytics, "acerca") }}>Acerca de</a></li>
                                    <li className="footer-list-item"><a href="/#contact" onClick={() => { logEvent(analytics, "contacto_visita") }}>Contacto</a></li>
                                </ul>
                            </div>
                            <div className="col-sm-3">
                                <ul className="list-unstyled footer-list">
                                    <p className="footer-list-item">Política de privacidad</p>
                                    <p className="footer-list-item">Términos y condiciones</p>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}