import { NavLink } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/layout.min.css';
import '../css/portafolio.css'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import Accordion from 'react-bootstrap/Accordion';
import { Nav } from "react-bootstrap";

function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images
}

const images = importAll(require.context('../img/', false, /\.(png|jpe?g|svg)$/));

export default function Portafolio() {
    return (
        <div className="">
            <Container fluid className="margin-t-20 margin-b-50">
                <Row className="margin-t-20 margin-b-20">
                    <Col sm>
                        <h1>Proyectos recientes:</h1>
                    </Col>
                </Row>
                <Accordion style={{ "accordion-icon-color": "white" }}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Button className="port-btn fs-5">Marketing digital</Accordion.Button>
                        <Accordion.Body className="clarito2">
                            <Row xs={1} sm={1} md={2} lg={2} xl={2} className="justify-content-center">
                                <Col className="no-pad-side">
                                    <Image fluid src={images["portafolio_01_1_crop.jpg"]} className="margin-b-20" />
                                </Col>
                                <Col className="no-pad-side">
                                    <Image fluid src={images["portafolio_01_2_crop.jpg"]} className="margin-b-20" />
                                </Col>
                                <p>
                                    A través del uso de herramientas como Facebook Ads y Google Ads, logramos promover negocios
                                    en la web, creando anuncios sustentados por un buen análisis de mercado y así incrementando
                                    la efectividad de los mismos, llegando a los targets buscados. Además, con ayuda de herramientas
                                    como Google Analytics monitoreamos distintas partes del proceso para un mejor análisis de las
                                    campañas publicitarias.
                                </p>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Button className="port-btn fs-5">E-commerce</Accordion.Button>
                        <Accordion.Body className="clarito2">
                            <Row xs={1} sm={1} md={2} lg={2} xl={2} className="justify-content-center">
                                <Col className="no-pad-side">
                                    <Image fluid src={images["portafolio_02_2_crop.jpg"]} className="margin-b-20" />
                                </Col>
                                <Col className="no-pad-side">
                                    <Image fluid src={images["portafolio_02_3_crop.jpg"]} className="margin-b-20" />
                                </Col>
                                <p>
                                    Expandimos tu tienda de productos a una en línea con nuestro conocimiento y uso de programas
                                    para gestionar productos, precios, envíos y más. Con la plataforma de pagos Stripe, hacemos
                                    más fácil los cobros en línea, con soporte para muchos métodos de pago, además de que se puede
                                    verificar en tiempo real el estatus de las compras, todo esto con la más alta seguridad en cobros
                                    para garantizar el mejor servicio de e-commerce.
                                </p>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Button className="port-btn fs-5">Autenticación de usuarios</Accordion.Button>
                        <Accordion.Body className="clarito2">
                            <Row xs={1} sm={1} md={2} lg={2} xl={2} className="justify-content-center">
                                <Col className="no-pad-side">
                                    <Image fluid src={images["portafolio_03_2.jpg"]} className="margin-b-20" />
                                </Col>
                                <Col className="no-pad-side">
                                    <Image fluid src={images["portafolio_03_3_crop.jpg"]} className="margin-b-20" />
                                </Col>
                                <p>
                                    Llevamos a cabo un proceso de verificación de usuarios para brindar una mayor seguridad
                                    y veracidad en las cuentas registradas de nuestros clientes y usuarios. Mediante firebase
                                    authentication, proporcionamos una solución de identidad de extremo a extremo, la cual es
                                    compatible con cuentas de correo electrónico, autenticación telefónica, acceso mediante Google, 
                                    Facebook, y mucho más.
                                </p>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Button className="port-btn fs-5">Bases de datos</Accordion.Button>
                        <Accordion.Body className="clarito2">
                            <Row xs={1} sm={1} md={2} lg={2} xl={2} className="justify-content-center">
                                <Col className="no-pad-side">
                                    <Image fluid src={images["portafolio_04_1_crop.jpg"]} className="margin-b-20" />
                                </Col>
                                <Col className="no-pad-side">
                                    <Image fluid src={images["portafolio_04_2_crop.jpg"]} className="margin-b-20" />
                                </Col>
                                <p>
                                    Con el uso de bases de datos, mantenemos la información de nuestros clientes asegurada
                                    y accesible en todo momento y en tiempo real para distintos propósitos. Con cloud firestore,
                                    un servicio de firebase de Google, poseemos una base de datos flexible y escalable para el 
                                    desarrollo móvil y web. De esta forma, garantizamos una manera segura y confiable de almacenar
                                    una gran cantidad de datos, como usuarios, productos, etc.
                                </p>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Button className="port-btn fs-5">Páginas web</Accordion.Button>
                        <Accordion.Body className="clarito2">
                            <Row xs={1} sm={1} md={2} lg={2} xl={2} className="justify-content-center">
                                <Col className="no-pad-side">
                                    <Image fluid src={images["Tabs_page2.jpg"]} className="margin-b-20" />
                                </Col>
                                <Col className="no-pad-side">
                                    <Image fluid src={images["PR_page2.jpg"]} className="margin-b-20" />
                                </Col>
                                <p>
                                    Acompañado de todo el proceso lógico que mantiene a flote nuestro servicio, ofrecemos
                                    también el diseño, implementación y mantenimiento de páginas, sitios y apps web, adaptadas
                                    a las necesidades e identidad de cada uno de nuestros clientes, con compatibilidad web y móvil
                                    para una mejor experiencia de usuario, además de un tiempo de respuesta rápido y a prueba de 
                                    errores.
                                </p>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Container>
        </div>
    );
}