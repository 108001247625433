import Accordion from 'react-bootstrap/Accordion';
import '../css/layout.min.css';
import '../css/accordion_about.css';

function AccordionAbout() {
    return (
        <Accordion defaultActiveKey="0" className='accordion' flush>
            <div className="panel-group">
                <Accordion.Item eventKey="0" className="panel panel-default">
                    <Accordion.Button className="panel-title acc-btn">Investigación profesional</Accordion.Button>
                    <Accordion.Body className="panel-body bg-success text-white">
                        Realizamos investigación de mercado altamente especializada para garantizar un
                        análisis de datos completo y funcional que llevan a mejores posicionamientos en
                        el mercado web.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className="panel panel-default">
                    <Accordion.Button className="panel-title acc-btn">Ingeniería avanzada</Accordion.Button>
                    <Accordion.Body className="panel-body bg-success text-white">
                        Utilizamos tecnología y software avanzado que asegura un servicio de calidad
                        en desarrollo de apps web e ingeniería de datos, sustentado y seguro, logrando
                        así mantener negocios actualizados y protegidos en la web.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className="panel panel-default">
                    <Accordion.Button className="panel-title acc-btn">Resultados sobresalientes</Accordion.Button>
                    <Accordion.Body className="panel-body bg-success text-white">
                        Ofrecemos entregar resultados claros, tangibles, fáciles de manejar y competentes
                        frente a otras consultoras web, impulsando a los negocios a expandirse y
                        mantenerse en la web con los mínimos requerimientos, siendo atractivos al público
                        y generando un crecimiento constante.
                    </Accordion.Body>
                </Accordion.Item>
            </div>

        </Accordion>
    );
}

export default AccordionAbout;