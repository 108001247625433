//import './App.css';
import '../css/layout.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';

import { BsEnvelope, BsWhatsapp } from 'react-icons/bs';
import NavbarHeader from './navbar';
import CarouselHome from './carousel-home';
import AccordionAbout from './accordion_about';
import ServiceCards from './service_cards';

import Swal from 'sweetalert2'

//firebase
import { analytics, db, logEvent } from "../config/fbconfig";
import { collection, addDoc, query, getDocs, where } from "@firebase/firestore";

function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
  return images
}

const images = importAll(require.context('../img/', false, /\.(png|jpe?g|svg)$/));

function MyForm() {
  const usersCollectionReference = collection(db, "mensajesClientes");
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await addDoc(usersCollectionReference, {
        nombre: inputs.username,
        email: inputs.mail,
        mensaje: inputs.message
      }).then(
        Swal.fire({
          icon: "success",
          title: "Perfecto!",
          text: "Su mensaje se ha enviado",
          confirmButtonText: "Continuar",
        })
      ).then(logEvent(analytics, "mensaje_enviado"))
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Hubo un problema al mandar el mensaje",
        confirmButtonText: "Continuar",
      }).then(logEvent(analytics, "mensaje_fallido"))
    }
  }

  return (
    <form onSubmit={handleSubmit} className='form-contact'>
      <div className="form-group-sm margin-b-10">
        <label>Nombre:</label>
        <input className='form-control'
          type="text"
          name="username"
          value={inputs.username || ""}
          onChange={handleChange}
        />
      </div>
      <div className="form-group-sm margin-b-10">
        <label>Correo:</label>
        <input className='form-control'
          type="email"
          name="mail"
          value={inputs.mail || ""}
          onChange={handleChange}
        />
      </div>
      <div className="form-group-sm margin-b-10">
        <label>Mensaje:</label>
        <textarea className='form-control'
          type="text"
          name="message"
          value={inputs.message || ""}
          onChange={handleChange}
        />
      </div>
      <input type="submit" className='btn btn-secondary' />
    </form>
  )
}



function Home() {
  return (
    <div>
      <CarouselHome id="inicio" controls='false'/>
      <div id="acerca">
        <div className="bg-color-sky-light">
          <div className="content-lg container">
            <div className="row">
              <div className="col-md-5 col-sm-5 md-margin-b-60">
                <div className="margin-t-50 margin-b-30">
                  <h2>¿Por qué nosotros?</h2>
                  <p>Somos una consultora especializada en ayudar a negocios tradicionales a implementar tecnología y adentrarlos al mercado de la web 3 y 4. Caracterizados por los siguientes atributos:</p>
                </div>
              </div>
              <div className="col-md-5 col-sm-7 col-md-offset-2">
                <AccordionAbout />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="servicios">
        <div className="bg-color-white" data-auto-height="true">
          <div className="content-lg container">
            <div className="row margin-b-40">
              <div className="col-sm-6">
                <h2>Servicios</h2>
                <p>Dentro de lo que ofrece Runbed se encuentran los siguientes servicios:</p>
              </div>
            </div>
            <div className="row row-space-10 margin-b-50">
              <ServiceCards></ServiceCards>
            </div>
          </div>
        </div>
      </div>
      <div id="contacto">
        <div className="section-seperator bg-color-sky-light">
          <div className="content-lg container">
            <h2>Contáctanos</h2>
            <div className="row">
              <div className="col-sm-4 sm-margin-b-50">
                <ul className="list-unstyled contact-list">
                  <li><BsWhatsapp /> +52 55 6141 2369</li>
                  <li><BsEnvelope /> askrunbed@runbed.com.mx</li>
                </ul>
              </div>
              <div className="col-sm-4 sm-margin-b-50">
                <h4 className='form-subtitle'>Escríbenos un mensaje</h4>
                <MyForm />
              </div>
            </div>
          </div>
        </div>
      </div>
      { /*<button className="js-back-to-top back-to-top">Top</button> */}
    </div>
  );
}

export default Home;