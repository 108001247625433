import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink, Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

//firebase
import { analytics, db, logEvent } from "../config/fbconfig";

function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
  return images
}

const images = importAll(require.context('../img/', false, /\.(png|jpe?g|svg)$/));

function NavbarHeader() {
  return (
    <Navbar bg="light" expand="lg" sticky='top' variant="light">
      <Container>
        <Navbar.Brand href="/#body">
          <img
            src={images["logo_runbed.png"]}
            width="80"
            height="70"
            className="d-inline-block align-top"
            alt="Runbed logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="me-auto">
            <Nav.Link href="/#inicio" onClick={() => { logEvent(analytics, "principal_visita") }}>Inicio</Nav.Link>
            <Nav.Link href="/#acerca" onClick={() => { logEvent(analytics, "acerca") }}>Acerca de</Nav.Link>
            <Nav.Link href="/#servicios" onClick={() => { logEvent(analytics, "servicios_visita") }}>Servicios</Nav.Link>
            <NavLink to="/portafolio" className="nav-link">Portafolio</NavLink>
            <Nav.Link href="/#contacto" onClick={() => { logEvent(analytics, "contacto_visita") }}>Contacto</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarHeader;