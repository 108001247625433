import Carousel from 'react-bootstrap/Carousel';

import '../css/layout.min.css';

function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images
}

const images = importAll(require.context('../img/', false, /\.(png|jpe?g|svg)$/));

function CarouselHome() {
    return (
        <Carousel controls={false}>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={images["home_01.jpg"]}
                    alt="Bienvenido a Runbed"
                />
                        <Carousel.Caption className='carousel-title'>
                            <h1 className='color-white'>Bienvenido a Runbed</h1>
                        </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={images["home_03.jpg"]}
                    alt="Soluciones digitales para negocios no digitales"
                />
                <Carousel.Caption className='carousel-title'>
                    <h1 className='color-white'>Soluciones digitales para negocios no digitales</h1>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}

export default CarouselHome;