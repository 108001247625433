import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import '../css/service_cards.css';

function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images
}

const images = importAll(require.context('../img/', false, /\.(png|jpe?g|svg)$/));

function ServiceCards() {
    return (
        <div>
            <CardGroup>
                <Card bg="success" text="white">
                    <Card.Img variant="top" src={images["services_01.jpg"]} />
                    <Card.Body>
                        <Card.Title>Consultoría digital</Card.Title>
                        <Card.Text className='text-white'>
                            Realizamos un posicionamiento de empresa para que sea conocida en la web.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card bg="light">
                    <Card.Img variant="top" src={images["services_02.jpg"]} />
                    <Card.Body>
                        <Card.Title>Investigación de mercado</Card.Title>
                        <Card.Text>
                            Realizamos recolección y análisis de información para planear una estrategia de marketing.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card bg="success" text="white">
                    <Card.Img variant="top" src={images["services_03.jpg"]} />
                    <Card.Body>
                        <Card.Title>Desarrollo de apps web</Card.Title>
                        <Card.Text className='text-white'>
                            Llevamos a cabo el diseño, implementación y mantenimiento de aplicaciones web.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </CardGroup>
            <CardGroup className='margin-t-20'>
                <Card bg="light" className=''>
                    <Card.Img variant="top" src={images["home_02.jpg"]} />
                    <Card.Body>
                        <Card.Title>Marketing digital</Card.Title>
                        <Card.Text>
                            Diseñamos estrategias en tiempo real para la promoción de empresas en la web.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card bg="success" text='white' className='h-100'>
                    <Card.Img variant="top" src={images["services_05.jpg"]} />
                    <Card.Body>
                        <Card.Title>SEO y Backlinks</Card.Title>
                        <Card.Text className='text-white'>
                            Optimizamos las apps web para garantizar un buen posicionamiento frente a otras apps en internet.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card bg="light" className=''>
                    <Card.Img variant="top" src={images["services_06.jpg"]} />
                    <Card.Body>
                        <Card.Title>Infraestructura digital</Card.Title>
                        <Card.Text>
                            Manejamos la transferencia información en la red de forma eficiente.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </CardGroup>
        </div>

    );
}

export default ServiceCards;